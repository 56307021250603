<template>
  <v-container id="voting-detail" fluid tag="section">
    <v-row justify="center">
      <dialog-message
        :dialog_message="dialog_message"
        :message="message"
      ></dialog-message>
      <v-col cols="12" md="11">
        <base-material-card>
          <template v-slot:heading>
            <div class="text-center text-h3 font-weight-medium">
              {{ meeting.urban_name }}
            </div>
            <div class="text-center text-h3 font-weight-medium">
              {{ meeting.title }}
            </div>
          </template>
          <v-col cols="12" class="text-center text-h3"
            >{{ $t("vote.issueTitle") }}{{ $t("signIn.title") }}</v-col
          >
          <v-col cols="12" class="text-center text-h3">{{
            TransDate(meeting.meeting_time)
          }}</v-col>
          <v-divider class="mx-4"></v-divider>
          <v-dialog v-model="dialog" max-width="90vw">
            <v-card>
              <v-card>
                <v-col class="text-left">
                  <v-chip class="ma-2" color="indigo" text-color="white"
                    >{{ $t("signIn.owner")
                    }}{{ selected_owner.owner_name }}：</v-chip
                  >
                  <template v-for="item in selected_options">
                    <v-chip
                      class="ma-2"
                      color="green"
                      text-color="white"
                      close
                      @click:close="selectOption(item)"
                      >{{ item.text }}</v-chip
                    >
                  </template>
                </v-col>
              </v-card>
              <v-card
                max-height="70vh"
                class="d-flex justify-space-around flex-wrap px-2 scroll"
              >
                <template v-for="item in options">
                  <v-card
                    class="pa-2 my-2"
                    :style="{ border: ifSelected(item) }"
                    :width="countOptionsWidth()"
                    @click="selectOption(item)"
                  >
                    <v-card-title
                      class="text-center pt-2 px-0 font-weight-medium"
                      >{{ item.text }}</v-card-title
                    >
                  </v-card>
                </template>
                <template v-for="empty_item in empty_options_count">
                  <div class="empty_item"></div>
                </template>
              </v-card>
              <v-card>
                <v-col class="text-right">
                  <v-btn color="aqua" class="mx-2" @click="closeDialog()">{{
                    $t("common.cancel")
                  }}</v-btn>
                  <v-btn
                    color="primary"
                    dark
                    class="mx-2"
                    :disabled="loading"
                    @click="sendSignInType()"
                    >{{ $t("common.confirm") }}</v-btn
                  >
                </v-col>
              </v-card>
            </v-card>
          </v-dialog>
          <v-card class="d-flex justify-space-around flex-wrap px-2">
            <template v-for="(item, index) in owners">
              <v-card
                class="pa-0"
                width="9.1%"
                @click="pickOwner(item)"
                :color="ifSignInCard(item)"
              >
                <v-card-text
                  class="text-center pa-2 py-4"
                  style="color:black;background-color:#d7f0c2"
                  >{{
                    index < 10 ? ("0" + (index + 1)).slice(-2) : index + 1
                  }}</v-card-text
                >
                <v-card-text
                  class="text-center font-weight-medium py-4 pa-2"
                  :style="{ color: ifSignInText(item) }"
                  >{{
                    Number(item.regno_no) < 10
                      ? ("0" + item.regno_no).slice(-2)
                      : item.regno_no
                  }}
                  {{
                    issue.hide_name
                      ? item.owner_name[0] + "OO"
                      : item.owner_name
                  }}</v-card-text
                >
              </v-card>
            </template>
            <template v-for="empty_item in empty_owners_count">
              <div class="empty_item"></div>
            </template>
          </v-card>
          <v-col cols="12" class="d-flex">
            <v-btn color="navy" class="mx-2" :disabled="loading" @click="getResult()">
              <v-icon left>mdi-download</v-icon>
              {{ $t("signIn.result") }}
            </v-btn>
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
            <v-spacer />
            <v-btn color="aqua" class="mx-2" @click="back()">{{
              $t("common.back")
            }}</v-btn>
          </v-col>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CommonUtil from "@/utils/commonutil.js";

export default {
  data: (vm) => ({
    loading: false,
    dialog_message: false,
    issue: { title: null },
    meeting: { meeting_time: null, urban_renewal: { name: null } },
    options: [
      {
        text: vm.$i18n.t("signIn.personal"),
        value: 1,
      },
      {
        text: vm.$i18n.t("signIn.commission"),
        value: 2,
      },
      {
        text: vm.$i18n.t("signIn.cancel"),
        value: 3,
      },
    ],
    owners: [],
    signIns: [],
    empty_owners_count: 0,
    empty_options_count: 0,
    selected_owner: { name: null },
    selected_options: [],
    selected_vote: null,
    updateVote: false,
    dialog: false,
    message: {
      title: "",
      text: "",
    },
    signInType:{
      // 親自出席
      Personally: 1,
      // 委託出席
      Commission: 2,
      // 取消出席
      Cancel: 3,
    }
  }),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getApi() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/meeting/" +
        this.meeting_id +
        "/sign_in/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.meeting = info.meeting;
      this.owners = info.owners;
      this.signIns = info.sign_ins;
      this.empty_owners_count = 10 - (this.owners.length % 10);
      if (this.options.length > 5) {
        this.empty_options_count = 10 - (this.options.length % 10);
      }
    },
    pickOwner(item) {
      this.dialog = true;
      this.selected_owner = item;
      let selectedOptions = [];
      this.signIns.forEach((element) => {
        if (element.owner == item.id) {
          this.updateVote = true;
          selectedOptions.push(element.status);
          this.selected_vote = element;
        }
      });
      selectedOptions.forEach((option_id) => {
        this.options.forEach((option) => {
          if (option.value == option_id) {
            this.selected_options.push(option);
          }
        });
      });
    },
    selectOption(item) {
      let exist = false;
      let i = 0;
      for (i; i < this.selected_options.length; i++) {
        if (this.selected_options[i] === item) {
          exist = true;
          break;
        }
      }
      if (exist) {
        this.selected_options.splice(i, 1);
      } else {
        this.selected_options = [];
        this.selected_options.push(item);
      }
    },
    sendSignInType() {
      // if (this.selected_options.length == 0) {
      //   return;
      // }
      let is_valid = true;
      let options_no_list = [];
      this.selected_options.forEach((element) => {
        options_no_list.push(element.id);
      });
      if (this.selected_vote) {
        this.patchSignInType(options_no_list, is_valid);
      } else {
        this.saveSignInType(options_no_list, is_valid);
      }
    },
    saveSignInType(options_no_list, is_valid) {
      if (options_no_list.length == 0) {
        if (!confirm(this.$i18n.t("signIn.NoSignInType"))) {
          return;
        }
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/signInRecord/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let data = {
        meeting: this.meeting_id,
        owner: this.selected_owner.id,
        status: this.selected_options[0].value,
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.getApi();
          currentObj.closeDialog();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    patchSignInType(options_no_list, is_valid) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/signInRecord/" +
        this.selected_vote.id +
        "/";

      const jsonData = {
        meeting: this.meeting_id,
        owner: this.selected_owner.id,
        status: this.selected_options[0].value,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          currentObj.getApi();
          currentObj.closeDialog();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
      this.dialog_form = false;
    },
    closeDialog() {
      this.dialog = false;
    },
    countOptionsWidth() {
      let option_width = "";
      if (this.options.length > 5) {
        option_width = "9.1%";
      } else {
        let item_width = 91 / this.options.length;
        option_width = item_width.toString() + "%";
      }
      return option_width;
    },
    ifSelected(item) {
      let color = "#ffffff";
      let i = 0;
      for (i; i < this.selected_options.length; i++) {
        if (this.selected_options[i] === item) {
          color = "#2699FB";
          break;
        }
      }
      return "8px solid " + color;
    },
    ifSignInCard(item) {
      let color = "#ffffff";
      this.signIns.forEach((element) => {
        if (element.owner == item.id) {
          switch(element.status){
            case this.signInType.Personally: 
              color = "#2699FB";
              break;
            case this.signInType.Commission: 
              color = "#ff9800";
              break;
            case this.signInType.Cancel: 
              color = "#ffffff";
              break;
          }
        }
      });
      return color;
    },
    ifSignInText(item) {
      let color = "#333333";
      this.signIns.forEach((element) => {
        if (element.owner == item.id) {
          if (element.status == 3) {
            color = "#333333";
          } else {
            color = "#ffffff";
          }
        }
      });
      return color;
    },
    back() {
      this.$router.push({
        name: "MeetingTables",
      });
    },
    getResult(){
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/meeting/" +
        this.meeting_id +
        "/getSignResult/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: 'arraybuffer'
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          let blob = new Blob([response.data], {type: "application/octet-stream"});
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          let date_str = CommonUtil.getDateString();
          let file_name = currentObj.meeting.urban_name + "_" + currentObj.meeting.title + "簽到結果_"+ date_str +".xlsx"
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.selected_options = [];
        this.updateVote = false;
        this.selected_vote = null;
      }
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("signIn.title"));
    this.meeting_id = this.$route.query.meeting;
    this.getApi();
  },
};
</script>

<style lang="scss" scoped>
.empty_item {
  width: 9.1%;
}
.scroll {
  overflow-y: scroll;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

.v-dialog
.v-chip
    .v-chip__content
        font-size: 16px
</style>